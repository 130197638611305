@font-face {
  font-family: 'Roboto';
  src: url(./assets/Roboto-Regular.ttf);
}

#content-desktop {
  display: block;
}
#content-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  #content-desktop {
    display: none;
  }
  #content-mobile {
    display: block;
  }
}

* {
  font-family: 'Roboto';
}

a {
  text-decoration: none !important;
}

body,
html {
  height: 100%;
  margin: 0;
}

.link-lookalike {
  color: #007bff !important;
  cursor: pointer;
}

.link-lookalike:hover {
  color: #0056b3 !important;
}
.picture {
  position: relative;
}
@media (max-width: 767.98px) {
  .picture {
    background-image: url('./assets/background-desktop.jpg');
  }
  .footer {
    background-color: #f7f7f7;
    text-align: center;
    padding: 25px;
  }
  .main-container {
    min-height: 100vh;
  }
}
@media (min-width: 767.98px) {
  .picture {
    background-image: url('./assets/background-desktop.jpg');
  }
  .footer {
    background-color: #f7f7f7;
    text-align: center;
    padding: 25px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .main-container {
    position: relative;
    min-height: 100vh;
  }

  .body-container {
    padding-bottom: 234px;
    height: 100%;
  }
  .center-at-page {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    margin: 0;
    padding: 0;
  }
  .dashboardContainer {
    min-height: calc(100vh - 56px);
  }
  .roomOverviewImage {
    min-width: 308px;
    max-height: 500px;
  }
  .registrationFlowImage {
    min-width: 308px;
    max-height: 500px;
  }
}

.mock {
  width: 100%;
  background-color: white;
  border-radius: 5px;
}

input[type='date'] {
  position: relative;
}

input[type='date']:after {
  content: '\25BC';
  color: #555;
  padding: 0 5px;
}

input[type='date']:hover:after {
  color: #bf1400;
}

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

input[type='date']::-webkit-clear-button {
  z-index: 1;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.react-bootstrap-table table {
  table-layout: auto;
}
.ribbon-content-wrapper {
  background: #f0871f;
  position: relative;
  z-index: 999;
  padding: 1em 2em;
}
.ribbon {
  font-size: 16px !important;
  width: 50%;
  position: relative;
  background: #f0871f;
  color: #fff;
  text-align: center;
  margin: 2em auto 3em;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  display: block;
  bottom: -1.4em;
  border: 1.5em solid #bb6b1c;
}
.ribbon:before {
  left: -1.8em;
  border-right-width: 1.7em;
  border-left-color: transparent;
}
.ribbon:after {
  right: -1.8em;
  border-left-width: 1.7em;
  border-right-color: transparent;
}
.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
  content: '';
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #aa671a transparent transparent transparent;
  bottom: -1em;
}
.ribbon .ribbon-content:before {
  left: 0;
  border-width: 1em 0 0 1em;
  z-index: 1;
}
.ribbon .ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
  z-index: 1;
}

.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.sideRibbon {
  width: 207px;
  height: 200px;
  overflow: hidden;
  position: absolute;
}
.sideRibbon::before,
.sideRibbon::after {
  position: absolute;
  z-index: 9999;
  content: '';
  display: block;
  border: 5px solid #f0871f;
}
.sideRibbon span {
  position: absolute;
  display: block;
  width: 300px;
  padding: 15px 0;
  background-color: #f0871f;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/ 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.sideRibbon-top-left {
  top: -4px;
  left: 5px;
}
.sideRibbon-top-left::before,
.sideRibbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.sideRibbon-top-left::before {
  top: -5px;
  right: 0px;
}
.sideRibbon-top-left::after {
  bottom: 0;
  left: 0;
}
.sideRibbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.registrationFlowSteps {
  display: inline-block;
  padding: 0.8em 3em;
  border: 0.12em solid grey;
  margin: 0 1.15em 0.5em 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000000;
  text-align: center;
  transition: all 0.15s;
  border-radius: 10px;
  font-size: 100%;
  text-align: center;
  padding-left: 9px;
  padding-right: 75px;
}

.registrationFlowStepsActive {
  display: inline-block;
  padding: 0.8em 3em;
  border: none;
  margin: 0 1.15em 0.5em 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
  transition: all 0.15s;
  border-radius: 10px;
  font-size: 100%;
  background-color: #007bff;
  border: 0.12em solid #007bff;
  padding-left: 9px;
  padding-right: 75px;
}

.registrationFlowNumber {
  height: 25px;
  width: 25px;
  border: 0.12em solid grey;
  border-radius: 50%;
  display: inline-block;
  opacity: 50%;
  text-align: center;
  margin-right: 28px;
}

.registrationFlowNumberActive {
  height: 25px;
  width: 25px;
  border: 0.12em solid white;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-right: 28px;
}

.table > :not(:first-child) {
  border-top: 0;
}

.badge {
  line-height: inherit !important;
}